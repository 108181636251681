// extracted by mini-css-extract-plugin
export var arrowIcon = "payload-module--arrow-icon--9996b";
export var bannerWithImageTitlesContainer = "payload-module--banner-with-image-titles-container--fbf14";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--a49c9";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--db3b7";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--ecd09";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--e92e3";
export var contactButtonImageSection = "payload-module--contact-button-image-section--4d65b";
export var contactButtonImageSectionContainer = "payload-module--contact-button-image-section-container--1d07f";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--2c20d";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--c2152";
export var content = "payload-module--content--ddbf5";
export var customParagraphTitle = "payload-module--custom-paragraph-title--61bfe";
export var customSubtitle = "payload-module--custom-subtitle--52daa";
export var imageContainer = "payload-module--image-container--0a22b";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--fc00d";
export var paragraphWithGap = "payload-module--paragraph-with-gap--47b87";
export var redirectingBoxesWithImagesAndTextInnerWrapper = "payload-module--redirecting-boxes-with-images-and-text-inner-wrapper--b1b1f";
export var sectionWithParagraphsSubtitle = "payload-module--section-with-paragraphs-subtitle--f1f10";
export var sectionWithParagraphsTitle = "payload-module--section-with-paragraphs-title--b289c";
export var sectionWithParagraphsTitleContainer = "payload-module--section-with-paragraphs-title-container--0f9cc";
export var singleBlockContainer = "payload-module--single-block-container--29f00";
export var testimonialTitle = "payload-module--testimonial-title--fa5ad";
export var titleBorderWidth = "payload-module--title-border-width--da507";
export var titlesContainer = "payload-module--titles-container--675b1";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--b1ccc";