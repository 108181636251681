import React from 'react';

import Layout from '@src/layouts';

import {
  MobileHeaderLayoutA,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  BlocksInRowsWithOneActive,
  ContactButtonImageSection,
  BlocksRedirectingToResources,
  MeetCodilimeSection,
  ColoredBlocksWithRedirects,
  NewClientSingleTestimonial,
  SectionWithParagraphs,
  RedirectingBoxesWithImagesAndText,
  BannerWithImage,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  networkServicesSEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveProps,
  contactButtonImageSectionProps,
  blocksRedirectingToResourcesProps,
  coloredBlocksWithRedirectsProps,
  testimonialProps,
  sectionWithParagraphsProps,
  redirectingBoxesWithImagesAndTextProps,
  bannerWithImageProps,
} from '@pages-impl/rd-services/payload';

export default function RDServices(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Let yourself innovate with our R&D team support"
      {...props}
    >
      <SEO {...networkServicesSEOProps} />
      <MobileHeaderLayoutA {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <RedirectingBoxesWithImagesAndText {...redirectingBoxesWithImagesAndTextProps} />
      <BannerWithImage {...bannerWithImageProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <SectionWithParagraphs {...sectionWithParagraphsProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <MeetCodilimeSection altText="Meet our company in numbers" />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
