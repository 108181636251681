import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';
import LinuxFoundation from '@images/logos-partners/Linux-Foundation-logo.svg';
import image1 from '@commons/redirecting-boxes-with-images-and-text/images/1.png';
import image2 from '@commons/redirecting-boxes-with-images-and-text/images/2.png';
import image3 from '@commons/redirecting-boxes-with-images-and-text/images/3.png';

import * as styles from './payload.module.scss';

export const networkServicesSEOProps = {
  title: 'R&D | Research and Development Services Company - CodiLime',
  description:
    'Our R&D department is where the ideas and innovations come to life. See how our research and development services can help your business.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Research and <br />
      development <br />
      services (R&D)
    </>
  ),
  isBorder: false,
};

export const sectionUnderHeaderProps = {
  contactButtonLabel: 'Innovate with us',
  textPartOne: (
    <>
      Innovative solutions don&apos;t come out of nowhere - they require extensive research, prototyping, and a trial
      and error approach. Our R&D team is exactly where these things happen. We combine different points
    </>
  ),
  textPartTwo: (
    <>
      {' '}
      of view and work with various experts to <b>spark new ideas and improve existing solutions.</b>
    </>
  ),
};

export const blocksInRowsWithOneActiveProps = {
  hasArrow: true,
  title: 'What we can do for you',
  subtitle: (
    <>
      Our goal is to turn innovation from an abstract concept to a tangible result that can be applied in real-life
      scenarios. We have the knowledge and experience with <b>both network and cloud technologies</b> that serve as a
      solid foundation for developing modern, virtualized solutions. <br className={styles.paragraphWithGap} />
      Here&apos;s how we provide assistance in research and development
      <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.arrowIcon}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.38633 11.9888C8.09344 11.6959 8.09344 11.2211 8.38634 10.9282L12.0643 7.25021L1.125 7.25021C0.710785 7.25021 0.374999 6.91442 0.374999 6.50021C0.374999 6.086 0.710785 5.75021 1.125 5.75021L12.0643 5.75021L8.38634 2.07221C8.09344 1.77932 8.09344 1.30444 8.38634 1.01155C8.67923 0.718655 9.1541 0.718655 9.447 1.01155L14.4053 5.96988C14.546 6.11053 14.625 6.3013 14.625 6.50021C14.625 6.69913 14.546 6.88989 14.4053 7.03054L9.44699 11.9888C9.1541 12.2817 8.67922 12.2817 8.38633 11.9888Z"
          fill="#22272F"
        />
      </svg>
    </>
  ),
  id: 'expertise-and-services',
  blocks: [
    {
      title: 'Research',
      description: (
        <>
          Our research activities are focused on modern networks and cloud technologies. This includes a wide range of
          cloud-native and Kubernetes solutions, service meshes, application networking, telemetry and observability,
          and artificial intelligence or machine learning. We&apos;re interested in Linux networking, virtual networks
          and all aspects of the evolution of network architectures. The results of research are{' '}
          <b>new ideas, concepts and often outlines of solution architectures.</b>
        </>
      ),
    },
    {
      title: 'Proof of Concept',
      description: (
        <>
          At CodiLime, we are practitioners. Therefore, after the extensive research phase of a project we turn to
          creating a proof of concept (PoC). We build demos to show <b>how new technologies can be used in practice</b>,
          integrate many logical components into a larger solution, and add improvements or new functionalities to
          existing solutions.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveSection,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.customSubtitle,
    singleBlockContainer: styles.singleBlockContainer,
    titlesContainer: styles.titlesContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
  },
  title: <>Reach for innovation with us</>,
  buttonTitle: 'Contact us',
  imageAlt: 'Reach for innovation with us',
  isH2: true,
  classNames: {
    customContent: styles.content,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionContainer,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our research and development activities',
    subtitle: (
      <>
        In our R&D, we are eager to share our know-how with broader audiences by publishing on our technology blog,
        recording webinars, podcasts and other video content, and taking part in conferences.
      </>
    ),
    classNames: {
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
    },
  },
  blocks: [
    {
      text: 'From Kubernetes Ingress to Kubernetes Gateway API',
      link: '/blog/kubernetes-ingress-kubernetes-gateway-api/',
    },
    {
      text: 'The modern, interoperable DC',
      link: 'https://www.youtube.com/watch?v=dmUGDgGUcsE&list=PL8eRhjTr138aQ-hDxfvo3n0-pEQP6FO33/',
    },
    {
      text: 'P4-programmable SmartNIC controlled by ONOS',
      link: '/blog/p4-programmable-smartnic-controlled-by-onos-video/',
    },
    {
      text: 'SFC for CNFs',
      link: 'https://www.youtube.com/watch?v=HDhdSJeSwks&list=PL8eRhjTr138YW8sVKkqXPjvA0IiOfQAkv/',
    },
    {
      text: 'Is Network Service Mesh a service mesh?',
      link: '/blog/is-network-service-mesh-a-service-mesh/',
    },
    {
      text: 'Introduction to CNI',
      link: 'https://www.youtube.com/watch?v=McIKOoPKgBk/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'See our other services',
  subtitle:
    'Our research and development team could not exist without input from specialists in various fields. On a daily basis, we work on projects requiring different knowledge and skill sets. This allows us to exceed your expectations and offer new insights that drive innovation in the software development process.',
  blocks: [
    {
      text: (
        <>
          <span>Data engineering services</span>
        </>
      ),
      link: paths.SERVICES.DATA_ENGINEERING,
    },
    {
      text: (
        <>
          <span>Network professional</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>Low-level programming</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.LOW_LEVEL,
    },
    {
      text: (
        <>
          <span>MVP development</span>
        </>
      ),
      link: paths.SERVICES.MVP,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksInnerWrapper,
  },
};

export const testimonialProps = {
  id: 'testimonial',
  testimonial: {
    quote: `We are excited to welcome CodiLime as a new member of the Linux Foundation and LF Networking. CodiLime’s expertise in network engineering will be a valuable asset especially for LF Networking, where we focus on collaborative open-source networking solutions. We admire the contribution CodiLime has made to Tungsten Fabric, a project that has been developed under the umbrella of the Linux Foundation. This is a sign of good things to come.`,
    author: 'Arpit Joshipura - General Manager, Networking, Edge, and IOT',
    logo: LinuxFoundation,
    logoAlt: 'The Linux Foundation',
  },
  leftTitle: 'What do our partners say about our R&D services?',
  classNames: {
    innerWrapper: styles.innerWrapperTestimonials,
  },
  paragraphTitleStyles: styles.testimonialTitle,
};

export const sectionWithParagraphsProps = {
  sectionProps: {
    id: 'process',
    title: 'Research and development process',
    subtitle:
      'The research and development process requires time and skill. We follow an established set of steps that allows us to first gather knowledge, and then turn it into a satisfying outcome.',
    classNames: {
      titleStyles: styles.sectionWithParagraphsTitle,
      subtitleStyles: styles.sectionWithParagraphsSubtitle,
      titlesContainer: styles.sectionWithParagraphsTitleContainer,
    },
  },
  paragraphsData: [
    {
      description: (
        <>
          The first step is to indicate <b>areas with potential for innovation and improvement</b>. We look at them from
          different perspectives and analyze them with various objectives in mind.
        </>
      ),
      customTitleContainerStyle: styles.customParagraphTitle,
    },
    {
      description: (
        <>
          Next, we deepen our insight through research. Then we analyze our findings and come up with particular{' '}
          <b>ideas and concepts.</b>
        </>
      ),
      customTitleContainerStyle: styles.customParagraphTitle,
    },
    {
      description: (
        <>
          At this point, we turn our ideas into reality. We present the <b>proposed solution architecture outlines</b>{' '}
          and iterate until we’re satisfied with the outcome.
        </>
      ),
      customTitleContainerStyle: styles.customParagraphTitle,
    },
    {
      description: (
        <>
          The last step is to prepare a <b>validated proof of concept</b>. This includes demonstrations presenting how
          the proposed solution can be used.
        </>
      ),
      customTitleContainerStyle: styles.customParagraphTitle,
    },
  ],
};

export const redirectingBoxesWithImagesAndTextProps = {
  sectionProps: {
    id: 'use-cases',
    title: 'Our R&D services in practice',
    subtitle: (
      <>
        CodiLime’s team of R&D professionals <b>supports our partners</b> on their innovation journey. We share our
        knowledge and provide modern solutions. With over 12 years of experience in the network industry, we offer
        hands-on expertise and extensive know-how. We also prepare training for external customers, including both
        theory and hands-on labs, if required.
      </>
    ),
    classNames: {
      innerWrapper: styles.redirectingBoxesWithImagesAndTextInnerWrapper,
    },
  },
  data: [
    {
      image: image1,
      title: 'Demo: Application networking in Kubernetes',
      textPartOne: (
        <span>We created a demo showing how to implement a single entry point for multiple APIs based on </span>
      ),
      textPartTwo: (
        <span>
          Kubernetes Ingress with extended functionality. For this we used the Contour controller with the Envoy proxy
          underneath. As part of the architecture of our solution, we also used web servers based on Python Flask and
          the MetalLB load-balancer.
        </span>
      ),
      link: 'https://youtu.be/yfvE-9_m5_o',
      label: 'Demo',
    },
    {
      image: image2,
      title: 'PoC: Service Function Chaining for CNFs',
      textPartOne: (
        <span>We built a PoC of SFC (service function chaining) for CNFs (cloud-native network functions). </span>
      ),
      textPartTwo: (
        <span>
          The CNFs, based on the VPP dataplane, were deployed in a Kubernetes cluster with the Network Service Mesh
          solution used for networking. We proposed a flexible approach to configuring CNFs so they react to constant
          changes in the network. In addition, we have created a simple observability platform based on Prometheus and
          Grafana.
        </span>
      ),
      link: 'https://youtu.be/sLKQSK84DxY',
    },
    {
      image: image3,
      title: 'PoC: P4 SmartNIC controlled by ONOS',
      textPartOne: (
        <span>We worked on a setup that allowed us to control the P4 SmartNIC using the open-source ONOS </span>
      ),
      textPartTwo: (
        <span>
          software. To make it possible, we developed a dedicated adapter. To the best of our knowledge, it was one of
          the first such solutions in the world, if not the first ever.
        </span>
      ),
      link: 'https://youtu.be/IwjoggSEOmI',
    },
  ],
};

export const bannerWithImageProps = {
  title: "Explore our R&D's custom SONiC solution",
  bannerCategory: 'Sonic',
  classNames: { titlesContainer: styles.bannerWithImageTitlesContainer },
};
